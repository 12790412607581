@import url("https://fonts.googleapis.com/css2?family=Archivo&display=swap");

@font-face {
  font-family: "noto_sans_thai";
  src: url("./font/notosansthai-regular-webfont.eot");
}

body {
  /* margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "noto_sans_thai", Arial, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* login */
.text-bold {
  font-weight: bold;
}
.justify-content-text {
  justify-content: space-around;
}
.con-h {
  height: 100vh;
}
.paddin-log {
  padding-top: 100px;
}
/* end login */

.bg-gray {
  background-color: #f3f3f5;
}
.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05) !important;
}
.nav-scroller {
  position: relative;
  z-index: 2;
  /* height: 4rem; */
  overflow-y: hidden;
  display: flex;
}
.nav-underline {
  /* height: 4rem; */
  align-content: center;
  padding: 0.5rem;
  padding-bottom: 2px;
  /* padding-left: 20px; */
}
.pd-nav {
  padding: 0.6rem 1rem;
}

.txt-nopro {
  color: #4f4f4f;
  padding-top: 4px;
  padding-bottom: 4px;
}

.txt-nopro.active {
  color: #000 !important;
  font-weight: bold;
  border-radius: 50px;
  background-color: #f0e3c5;
  border: 1px solid #eed6a1;
}

.txt-nopro:hover {
  color: #000 !important;
  font-weight: bold;
  border-radius: 50px;
  background-color: #f0e3c5;
  border: 1px solid #eed6a1;
}

.txt-style {
  color: #4f4f4f;
  font-size: 12px;
}

.txt-style:hover {
  /* color:#000; */
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
}
.txt-style.active {
  color: #000 !important;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 3px solid #e6af2e;
  /* margin-bottom: 5px; */
}
.justify-content-end-table {
  justify-content: flex-end !important;
}
.btn-logout {
  background-color: #e6af2e !important;
  color: #ffffff;
  border-color: #e6af2e !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
}
.btn-action {
  background-color: #ffffff !important;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 18px;
  color: #4f4f4f !important;
  border-color: #ffffff !important;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
.btn-action:hover {
  background-color: #ffffff !important;
  color: #4f4f4f !important;
  border-color: #ffffff !important;
}
.btn-action:active {
  background-color: #ffffff !important;
  color: #4f4f4f !important;
  border-color: #ffffff !important;
}
.btn-action:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ffffff !important;
}
.btn-quick {
  background-color: #e6af2e !important;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border-color: #e6af2e !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  padding: 0px 10px;
}
.btn-quick:hover {
  background-color: #e6af2e !important;
  color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  border-color: #e6af2e !important;
}
.btn-quick:active {
  background-color: #e6af2e !important;
  color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
  border-color: #e6af2e !important;
}
.btn-quick:focus {
  outline: 0;
  border-color: #e6af2e !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08) !important;
}
.buttom-py {
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-table {
  font-weight: bold !important;
  font-size: 12px !important;
  color: #fff !important;
  background-color: black;
  /* text-align: center; */
}
.bt-0 {
  padding-bottom: 0px;
}
/* .table-width{
  width: inherit;
} */
.table-width > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-header > .ant-table-thead > tr > th {
  width: inherit;
  padding: 15px 15px !important;
  overflow-wrap: break-word !important;
}
.table-width .ant-table-column-sorters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.table-width .ant-table-column-sorter-inner {
  margin-left: 0.75em;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.table-width span.anticon {
  vertical-align: unset;
}
.table-width span.anticon svg {
  overflow: unset;
  vertical-align: unset;
}
/* .table-width .ant-table-column-sorter-inner .ant-table-column-sorter-up.active {
  color: red;
}
.table-width .ant-table-column-sorter-inner .ant-table-column-sorter-down.active {
  color: red;
} */
.w-table {
  width: 160px;
}
.text-probold {
  font-size: 14px !important;
  font-weight: bold;
  background-color: #fff !important;
}
.text-start {
  text-align: start;
}
.text-align {
  align-items: center !important;
  /* margin-top: 15px; */
  color: #1877f2;
  font-weight: bold;
}
.text-detail {
  align-items: center !important;
  /* margin-top: 10px; */
  color: #898989;
  font-weight: 400;
}
.txt-poin {
  align-items: center !important;
  color: #000;
  font-weight: bold;
  /* margin-top: 5px; */
}
.btn-status {
  border: 1px #f5f5f5 solid;
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f5f5f5;
  color: #333333;
  cursor: default;
  margin-bottom: 11px;
  border-top-width: 0px;
}
.wh-star {
  width: 1.5em !important;
  height: 1.5em !important;
  color: #e6af2e;
}
.wh-star-n {
  width: 1.5em !important;
  height: 1.5em !important;
  color: #e0e0e0;
}
.icon-more {
  align-self: center !important;
  width: 2em !important;
  height: 30px !important;
  color: #4f4f4f;
}
.icon-sort {
  width: 1.5em !important;
  height: 1.5em !important;
  color: #fff;
}
.icon-quick {
  width: 1.5rem !important;
  height: 1.5rem !important;
  color: #fff;
}
.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.text-color-whirth {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: bold;
}
.text-color-whirth.active {
  color: #ffffff !important;
  text-decoration: unset;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #e6af2e;
}
.background-color-headfar {
  background: #000;
  display: flex;
  flex-direction: row;
  min-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.profile-header {
  background-color: #fff !important;
  border-radius: 2rem;
  padding: 2px;
  width: 100px;
  display: flex;
  justify-content: space-between;
}
.icon-profile {
  align-self: center;
  margin-left: 20px;
}
.color-gray {
  color: #828282;
}
.color-black {
  color: #282828;
}
.margin-text-input {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
}
.text-end {
  text-align: end;
}
.btn-cancel {
  background-color: transparent !important;
  color: #333333 !important;
  border-color: transparent !important;
}
.btn-cancel:focus {
  box-shadow: 0 2px 2px 0 rgba(95, 93, 93, 0.16), 0 0 0 1px rgba(173, 171, 171, 0.08) !important;
  background-color: rgba(0, 0, 0, 0.26) !important;
}
.btn-cancel-extarct {
  background-color: transparent !important;
  color: #333333 !important;
  border-color: #e0e0e0 !important;
}
.btn-cancel-extarct:focus {
  box-shadow: 0 2px 2px 0 rgba(95, 93, 93, 0.16), 0 0 0 1px rgba(173, 171, 171, 0.08) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.btn-cancel-extarct:hover {
  box-shadow: 0 2px 2px 0 rgba(95, 93, 93, 0.16), 0 0 0 1px rgba(173, 171, 171, 0.08) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.cursor-auto {
  cursor: auto !important;
}
.btn-reset {
  background-color: #cccbc8 !important;
  color: #3e3d3c !important;
  border-color: #cccbc8 !important;
}
.btn-reset:hover {
  background-color: #dddbd7 !important;
  color: #3e3d3c !important;
  border-color: #dddbd7 !important;
  box-shadow: 0 0 0 0.2rem #dddbd7 !important;
}
.btn-reset:focus {
  background-color: #787776 !important;
  color: #3e3d3c !important;
  border-color: #787776 !important;
  box-shadow: 0 0 0 0.2rem #787776 !important;
}
.btn-reset.active {
  background-color: #787776 !important;
  color: #3e3d3c !important;
  border-color: #787776 !important;
}

.btn-war {
  background-color: #f7cc64 !important;
  color: #fff !important;
  border-color: #f0e68c !important;
}
.btn-war:hover {
  background-color: #f9e797 !important;
  color: #fff !important;
  border-color: #f9e797 !important;
  box-shadow: 0 0 0 0.2rem #f9e797 !important;
}
.btn-war:focus {
  background-color: #f9e797 !important;
  color: #fff !important;
  border-color: #f9e797 !important;
  box-shadow: 0 0 0 0.2rem #f9e797 !important;
}
.btn-war.active {
  background-color: #f4de7c !important;
  color: #fff !important;
  border-color: #f0e68c !important;
}
.btn-search {
  background-color: #ffc107 !important;
  color: #ffffff !important;
  border-color: #ffc107 !important;
}
.btn-search:hover {
  background-color: #ffc107 !important;
  color: #fff !important;
  border-color: #ffc107 !important;
  box-shadow: 0 0 0 0.2rem #ffc107 !important;
}
.btn-search:focus {
  background-color: #ffc107 !important;
  color: #fff !important;
  border-color: #ffc107 !important;
  box-shadow: 0 0 0 0.2rem #ffc107 !important;
}
.btn-search.active {
  background-color: #ffc107 !important;
  color: #fff !important;
  border-color: #ffc107 !important;
}
.btn-save {
  background-color: transparent !important;
  color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
.btn-save:hover {
  background-color: transparent !important;
  color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  box-shadow: 0 0 0 0.2rem #ffffff !important;
}
.btn-save:focus {
  background-color: transparent !important;
  color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
  box-shadow: 0 0 0 0.2rem #ffffff !important;
}
.btn-save.active {
  background-color: #fff !important;
  color: #000000 !important;
  border-color: #e0e0e0 !important;
}
.btn-addmore {
  background-color: #bdbdbd !important;
  color: #fff !important;
  border-color: transparent !important;
}
.btn-addmore:hover {
  background-color: #bdbdbd !important;
  color: #ffffff !important;
  border-color: #e0e0e0 !important;
  box-shadow: 0 0 0 0.2rem #ffffff !important;
}
.btn-addmore:focus {
  background-color: #bdbdbd !important;
  color: #ffffff !important;
  border-color: #e0e0e0 !important;
  box-shadow: 0 0 0 0.2rem #ffffff !important;
}
.btn-addmore.active {
  background-color: #e6af2e !important;
  color: #fff !important;
  border-color: transparent !important;
}
.btn-addmore-extract {
  background-color: #343a40 !important;
  color: #fff !important;
  border-color: transparent !important;
}
.btn-addmore-extract.active {
  background-color: #212529 !important;
  color: #fff !important;
  border-color: transparent !important;
}
.btn-addmore-extract:hover {
  background-color: #343a40 !important;
  color: #ffffff !important;
  border-color: #343a40 !important;
  box-shadow: 0 0 0 0.2rem #ffffff !important;
}
.width-full {
  margin-left: 0px;
  margin-right: 0px;
  max-width: 100%;
  background-color: #f3f3f5 !important;
}

.table-over {
  overflow-x: auto;
}

.align-ver {
  vertical-align: inherit !important;
}
.a-self {
  align-self: center;
}

.tabNav {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 12px 20px 0px 20px;
}

.tabNav .nav-item {
  margin-right: 20px;
}

.tabNav .nav-link {
  color: #4f4f4f;
  /* border-bottom: 2px solid #E6AF2E; */
  border-radius: 0;
  padding: 0px 0px 12px 0px;
  /* padding-bottom: 12px; */
}

.tabNav .nav-link img {
  opacity: 0.5;
}

.tabNav .nav-link.active {
  background-color: #fff;
  color: #0a0a0a;
  font-weight: bold;
  border-bottom: 2px solid #e6af2e;
}

.tabNav .nav-link.active img {
  opacity: 1;
}

.normalLink {
  color: #4f4f4f;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.btnNoborder {
  color: #4f4f4f;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
}
.btnNoborder:active {
  border: none;
}

.normalLink:hover,
.normalLink:active {
  color: #e6af2e;
  text-decoration: none;
}

.textSize12 {
  font-size: 12px;
}

.uploadBox {
  border: 1px dashed #000000;
  padding: 26px;
  align-items: center;
  vertical-align: middle;
}

/* ค้นหา */
.position-ig {
  position: absolute;
}
.h-ig {
  height: 300px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  top: 80px;
  z-index: 1;
}
.swal-wide {
  width: 420px !important;
  height: 320px !important;
}
.img-profile {
  width: 51px !important;
  height: 45px !important;
  border-radius: 60px !important;
}
.list-g {
  padding: 5px 0px 5px 5px !important;
}
.img-sale {
  width: 42px !important;
  height: 40px !important;
  border-radius: 60px !important;
}
.img-acc {
  width: 60px !important;
  height: 60px !important;
  border-radius: 60px !important;
}

.img-topic {
  width: 35px !important;
  height: 35px !important;
  border-radius: 60px !important;
}
.card-ig {
  background-color: #f9f9f9;
  border-bottom: none !important;
}
.border-radius {
  border-radius: 50px !important;
}
.text-ig {
  font-size: 20px;
  font-weight: bold;
}
.bg-card {
  background-color: #f9f9f9;
}
.label-text {
  font-size: 15px;
  color: gray;
}

/* popup */
.w-pop {
  width: 180px;
}
.h-43 {
  height: 43px;
}
.point3 {
  cursor: pointer;
  color: #000;
  text-decoration: inherit;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.point3:hover {
  color: #bdbdbd !important;
  font-weight: 400 !important;
}
.point3:active {
  color: #000 !important;
  font-weight: 400 !important;
  background-color: unset;
}
.point-delete {
  color: #ff1212 !important;
}
.point-delete:hover {
  color: #f55a5aba !important;
  text-decoration: inherit;
}
.point-delete:focus {
  color: #ff1212 !important;
}
.cursor {
  cursor: pointer;
}

.position-modal {
  position: relative;
}
.position-igmodal {
  position: absolute;
  margin-left: 10px;
  margin-top: 7px;
}
.position-pic-sale {
  position: absolute;
  width: 40px;
  height: 30px;
  border-radius: 50px;
}

.pading-ig {
  padding-left: 30px;
}

.pading-sale {
  padding-left: 40px;
}

.tabNavHaed {
  /* margin-bottom: 10px; */
  background-color: #000;
  /* padding: 12px 20px 0px 20px; */
  color: #fff !important;
  font-size: 1rem !important;
}

.tabNavHaed .nav-item {
  margin-right: 20px;
  font-size: 1rem;
}

.tabNavHaed .nav-link {
  color: #fff !important;
  border-radius: 0;
  font-size: 1rem !important;
}

.tabNavHaed .nav-link img {
  opacity: 0.5;
}

.tabNavHaed .nav-link.active {
  background-color: #000;
  color: #fff !important;
  font-weight: bold;
  border-bottom: 3px solid #e6af2e;
}
.just-f-end {
  justify-content: flex-end;
}
.img-change-sale {
  width: 34px !important;
  height: 32px !important;
  border-radius: 90px !important;
  margin-right: 5px !important;
}
#modal_loading {
  z-index: 1600;
}
#modal_loading .modal-body {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
#modal_loading .modal-loadding {
  margin-top: 70%;
}
#modal_loading .modal-content {
  background-color: transparent;
  border: none;
}
.bg-modal {
  background-color: #fff !important;
}
.font-blue {
  color: rgb(65, 160, 252);
}
.font-gray {
  color: gray;
}
.font-yellow {
  color: #e6af2e;
}

/* pagination */

.webboard-pagination .pagination .page-item {
  margin-right: 1rem;
}

.webboard-pagination .pagination .page-item:hover {
  opacity: 0.9;
}

.webboard-pagination .pagination .page-item.disabled:hover {
  opacity: 1;
}
.webboard-pagination .pagination .page-item a,
.webboard-pagination .pagination .page-item span {
  border-radius: 0.35rem;
  background-color: #ffd100;
  color: #292929;
}
.webboard-pagination .pagination .page-item a {
  border: 2px solid #ffd100;
}
.webboard-pagination .pagination .page-item.disabled span {
  background-color: #d9d9d9;
  color: #292929;
}
.webboard-pagination .pagination .page-item.active span {
  background-color: #f8fafd;
  border: 2px solid #fce67c;
  color: #292929;
}

/* ant Table */

.ant-pagination {
  display: flex;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: " ";
}

.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transition: none;
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1890ff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #40a9ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #40a9ff;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #40a9ff;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  opacity: 1;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #1890ff;
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}

a:active {
  color: #ffffff !important;
}

.ant-table-thead {
  background-color: #0a0a0a;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
.ant-table-tbody {
  background-color: #fff;
}
.ant-table-row {
  border-bottom: 1px solid #f2f2f2;
}
.table-col {
  /* padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px; */
  padding: 5px;
}
.star1 {
  width: 50px;
  height: 50px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.icon-calender {
  position: relative;
}
.icon-item-calender {
  position: absolute;
  margin: 10px;
}
.ant-empty-description {
  text-align: center;
  font-weight: bold;
}
.w-select {
  width: 150px;
}
.width-select {
  width: 180px;
}
.w-foller {
  width: 150px;
}
.edit-back {
  font-weight: 500;
  font-size: 20px;
}
a {
  color: #000 !important;
  text-decoration: none !important;
  /* background-color: transparent!important; */
}
a:hover {
  color: #646669 !important;
  /* text-decoration: underline; */
}
.font-24 {
  font-size: 24px;
}
.font-10 {
  font-size: 10px;
}
.bg-black {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}
.txt-style-modal {
  color: #4f4f4f;
  font-size: 12px;
}
.MuiInput-underline {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}
.MuiInput-underline:after {
  border-bottom: 0px solid #ced4da !important;
}
.MuiInput-underline:before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}
.auto-com {
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  padding-bottom: 0 !important;
  padding-left: 10px !important;
  padding-right: 5px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.MuiAutocomplete-tag {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.h-list {
  height: 300px;
  overflow-y: auto;
  position: absolute;
  width: 95%;
  /* top: 50px; */
  z-index: 1;
}

.h-list .list-group-item:hover {
  color: #007bff;
}

.bg-form {
  background-color: #fff !important;
}
.row-check {
  position: absolute;
  margin-top: 5px;
}

.ant-checkbox-wrapper {
  margin-left: 10px;
  margin-bottom: 0px;
}

.ant-pagination-options {
  display: none !important;
}

.flex-icon {
  flex: 0.9;
}

/* silde Bar */
.card-padding {
  padding-top: 15px !important;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.icon-silde {
  position: absolute;
  left: 235px;
  top: 21px;
  z-index: 999;
  font-size: 25px;
  color: #1877f2;
}

.icon-sildeLeft {
  position: absolute;
  margin-left: 3px;
  z-index: 999;
  margin-top: 21px;
  font-size: 25px;
  color: #1877f2;
}

.sidebar {
  height: 100%;
  width: 0;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  margin-left: 15px;
}

/* .sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
} */

.open {
  cursor: pointer;
  background-color: #fff;
  color: #000;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  width: 246px !important;
  margin-left: 0px;
}
.position-side {
  position: relative;
  flex-direction: row;
}
.flex-side {
  flex: 1;
}
.hight-input {
  height: 32px;
}
::placeholder {
  font-size: 12px;
  color: #bfbfbf !important;
}
.position-input {
  position: absolute;
  right: 20px;
  margin-top: 6px;
  color: #dadada;
  font-size: 14px;
}
.justify-side {
  justify-content: space-between;
}
.magin-check {
  margin-bottom: -15px;
}
.form-check-label {
  font-size: 12px;
  margin-bottom: 0px;
}
.text-btn {
  color: #fff;
}
.text-btn:hover {
  color: #fff !important;
}
.text-btn:active {
  color: #fff !important;
}
.magin-btn-side {
  margin-top: 37px;
}

.overFlow {
  overflow-y: auto;
  max-height: 200px;
}

.ant-table-expanded-row-fixed {
  width: 100% !important;
}
.childen-table {
  flex: 1;
  overflow: auto;
  background-color: #f3f3f5 !important;
}

.btn-paddingsave {
  padding-bottom: 10px;
}

.background-modal-knowledge {
  background: #000 !important;
}

.tabNavGeneral {
  background-color: #000;
  padding: 10px 12px;
}

.tabNavGeneral .nav-item {
  margin-right: 20px;
}

.tabNavGeneral .nav-link {
  color: #fff !important;
  border-radius: 0;
  padding: 0px;
}

.tabNavGeneral .nav-link img {
  opacity: 0.5;
}

.tabNavGeneral .nav-link.active {
  background-color: #000;
  color: #fff !important;
  font-weight: bold;
  border-bottom: 2px solid #e6af2e;
}

.tabNavGeneral .nav-link.active img {
  opacity: 1;
}

.background-tab {
  background-color: #fff;
  /* margin-top: 5px; */
}

.tabNavDocument .nav-link {
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000 !important;
  background-color: #fff !important;
  border-radius: 3px;
}

.tabNavDocument .nav-link img {
  opacity: 0.5;
}

.tabNavDocument .nav-link.active {
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  color: #1877f2 !important;
  font-weight: normal;
  border: 1px solid #1877f2;
  background-color: #fff !important;
}

.tabNavDocument .nav-link.active img {
  opacity: 1;
}
.padding-tab {
  padding-right: 30px;
}
.padding-navTab {
  padding-left: 25px;
}

.txt-modal {
  color: #000;
  border-radius: 50px;
  border: 1px solid #000;
  height: 30px;
}

.tag-text {
  font-weight: 300;
}

.txt-modal.active {
  color: #fff !important;
  border-radius: 20px;
  background-color: #000;
  border: 1px solid #000;
}
.card-border {
  border: none !important;
  /* width: 18rem; */
}
.text-load {
  color: #007bff !important;
}

.nav-doc {
  align-content: center;
  padding: 0.5rem;
  padding-bottom: 2px;
}

.nav-linkDoc {
  display: block;
  padding: 0.3rem 1rem !important;
}

.form-magin {
  margin-top: 3px;
}

.select-form-container .control {
  display: block;
  width: 100%;
  /* height: calc(1.65em + .75rem + 2px); */
  height: 100%;
  padding: 0.375rem 0.35rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.select-form-container .control.onfocus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select-form-container input:not(.form-control) {
  box-sizing: content-box;
  background: 0px center;
  border: 0px;
  font-size: inherit;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  color: inherit;
}

.justify-row {
  justify-content: space-evenly;
}
.card-hight {
  height: 200px;
  overflow: auto;
}
.img-wight {
  width: auto !important;
  height: 100% !important;
}

.file-width {
  width: 230px;
}

.h-sale {
  height: 35px;
}

.status-report .ant-table-thead {
  background-color: #fff !important;
  color: black;
}

.status-report .table-col .status-name {
  font-size: 0.9em;
  font-weight: 600;
  padding: 0.5em 0.75em;
  border-radius: 5px;
}

.status-report .table-col .status-name.is-workflow {
  background-color: #fbd675;
}

.status-report .table-col .status-name.not-workflow {
  background-color: #fef2d3;
}

.master-data-table .ant-table th.ant-table-selection-column .ant-table-selection {
  margin-right: 0.5em;
  max-width: none;
}

.invalid-error {
  color: #dc3545;
  font-size: 12px;
}

.admin-menu,
.ant-menu,
.ant-menu .ant-menu-item {
  background-color: #f3f3f5 !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100%) !important;
}

.ant-menu-inline .ant-menu-item-selected::after {
  transform: none !important;
  opacity: 0 !important;
  transition: unset !important;
}


.upload-btn-wrapper {
  position: relative;
	overflow: hidden;
  transition: all .3s ease;
  height: 10vmax;
  border-radius: 50%;
}

.upload-btn-wrapper:hover {
	cursor: pointer;
}

.profile-pic {
  height: 100%;
  width: 100%;
  transition: all .3s ease;
  
}
.profile-pic:after{
  font-family: FontAwesome;
  content: "\f007";
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 190px;
  background: #ecf0f1;
  color: #34495e;
  text-align: center;
}

.upload-btn-wrapper .upload-btn {
  /* border: none; */
  opacity: 0.75;
  /* background-color: transparent; */
  color: #f5a81e;
  height: 100%;
  width: 100%;
  /* font-size: 20px;
  font-weight: bold;*/
  position: absolute;
  left: 0;
  top: 0; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper .upload-btn svg{
  position: absolute;
  display: flex;
  font-size: 2vmax;
  bottom: 12%;
  right: 12%;
  text-align: center;
  opacity: .5;
  transition: all .3s ease;
  color: #999999;
}

.upload-btn-wrapper:hover .upload-btn svg{
  opacity: 0.9 !important;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}
.user-image img {
  width: 7rem;
  height: 7rem;
}

.btn-repass {
  background-color: #fff !important;
  color: #0a0a0a !important;
  border-color: #0a0a0a !important;
}
.btn-repass:hover {
  background-color: #fff !important;
  color: #0a0a0a !important;
  border-color: #0a0a0a !important;
  box-shadow: 0 0 0 0.1rem #0a0a0a !important;
}
.btn-repass:focus {
  background-color: #fff !important;
  color: #0a0a0a !important;
  border-color: #0a0a0a !important;
  box-shadow: 0 0 0 0.1rem #0a0a0a !important;
}
.btn-repass.active {
  background-color: #fff !important;
  color: #0a0a0a !important;
  border-color: #0a0a0a !important;
}

.btn-reset-password {
  background-color: #E6AF2E;
  color: white;
}

.btn-topic {
  background-color: #0A0A0A;
  color: white;
}
.btn-topic:hover {
  background-color: #0A0A0A;
  color: white;
  box-shadow: 0 0 0 0.1rem #bdbdbd !important;
}

.inner-addon { 
  position: relative; 
  display: flex;
  align-items: center;
  width: 100%;
}

.inner-addon svg.input-icon {
  position: absolute;
  /* padding: 10px; */
  pointer-events: none;
  color: gray;
}

.left-addon svg.input-icon { left:  10px;}
.right-addon svg.input-icon { right: 10px;}

.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }

.select-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.rich-text-invalid {
  border: 1px solid #dc3545;
}

.ql-editor{
  min-height:200px;
}

.img-topic {
  width: 30px !important;
  height: 30px !important;
  border-radius: 60px !important;
}

#topic-form .modal-content, #topic-detail .modal-content {
  border: 1px solid rgba(0,0,0,.25);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.75);
}

.btn-remove {
  border: 1px solid #dc3545;
  color: #dc3545;
  border-radius: 50px;
  /* width: 2em;
  height: 2em; */
}
.btn-criteria:focus {
  box-shadow: unset !important;
}

#product-workflow-modal .card.active {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 1024px) and (max-width: 1125px) {
  .file-width {
    width: 190px;
  }
}
